import React from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import RequestJoinForm from '../components/pages/partner/RequestJoinForm';

const Partner = ({ location }) => {
  const { t } = useTranslation();
  return (
    <PageContainer pageTitle={t('navMenu.partner')} location={location}>
      <RequestJoinForm />
    </PageContainer>
  );
};

export default Partner;
